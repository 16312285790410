import React from 'react';
import { db } from '../services/firebase.js';
import WorkshopsCard from './WorkshopsCard.js';

export default class MasterSchedule extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        componentData: []
      };
    }

    componentDidMount() {
      var dbRef = db.ref('events/');
      const mastSchedData = []

      dbRef.once("value", function(snapshot) {
          snapshot.forEach(function(doc) {
            var msItem = doc.val();
            msItem.key = doc.id;
            mastSchedData.push(msItem);
          })
          const cleanWkspData = mastSchedData.sort((a,b) => a.startTime.seconds - b.startTime.seconds);
          if (cleanWkspData.length > 4) {
            cleanWkspData.filter((wksp) => wksp.startTime >= + new Date() / 1000);
          }
          this.setState({componentData: cleanWkspData });
        }.bind(this));
    }

    render() {
      const masterSchedCards = [];
      const { componentData } = this.state;

      var moment = require('moment');

      componentData.forEach((msDetail) => {
        var dateObj = moment(new Date(msDetail.startTime.seconds * 1000)).format('MM/DD/YY');
        var startTimeObj = moment(new Date(msDetail.startTime.seconds * 1000)).add(4, 'hours').format('hh:mm A');
        var endTimeObj = moment(new Date(msDetail.endTime.seconds * 1000)).add(4, 'hours').format('hh:mm A');
        masterSchedCards.push(
          <WorkshopsCard
            key={msDetail.eventType}
            title={msDetail.title}
            type={msDetail.eventType}
            location={msDetail.eventType}
            date={dateObj}
            startTime={startTimeObj}
            endTime={endTimeObj}
          />
        );
      });

      return (
        <div className="wkspsched">
            <b>MASTER SCHEDULE</b>
            <div className="wkspScroll">
                {masterSchedCards}
            </div>
        </div>
      );
    }
}
