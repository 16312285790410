import React from 'react';
import KHLogo from '../assets/kh-logo.jpg';

function Header() {
    return (
        <div className="header">
            <img className="avatar" src={KHLogo} alt="knight hacks logo avatar"></img>
            <div className="live">LIVE</div>
        </div>
    )
}

export default Header;