import React from 'react';
// Imports firebase into the component

export default class LiveUpdatesCard extends React.Component {
    render() {
      return (
        <div className="updatesMessage">
            {this.props.message}
            <div className="messageDivider">
              {this.props.timeSent}
            </div>
            <div className="lineDivider"></div>
        </div>
      )
    }
}
