import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import moment from 'moment-timezone';

const Countdown = () => {
    const calculateTimeLeft = () => {
        const rightNow = new moment().tz('America/New_York')
        const beginningOfKH = new moment(new Date('10/09/2020 22:00:00')).tz('America/New_York');
        let start;
        if (rightNow < beginningOfKH) {
            start = beginningOfKH;
        } else {
            start = rightNow;
        }
        let end = new moment(new Date('10/11/2020 10:00:00')).tz('America/New_York')
        let difference = end - start;
        if (difference < 0) {
            difference = 0;
        }
        let timeLeftObj = {};
        // if (difference > 0) {
        timeLeftObj = {
            hours: Math.floor((difference / (1000 * 60 * 60))),
            minutes: Math.floor((difference / 1000 / 60) % 60),
            seconds: Math.floor((difference / 1000) % 60)
        };
        // }
        for(const key of Object.keys(timeLeftObj)) {
            if (timeLeftObj[key] < 10) {
                timeLeftObj[key] = `0${timeLeftObj[key]}`
            }
        }
    
        return timeLeftObj;
    }

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setTimeout(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);
        return () => clearTimeout(timer);
    });
    return (
        <div className="countdown">
            <div className="clock" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                <div className="time">
                    {`${timeLeft['hours']}:${timeLeft['minutes']}:${timeLeft['seconds']}`}
                </div>
                <div>
                    Until hacking ends!
                </div>
            </div>
            <div className="info">
                {/* <div className="row" style={{ margin: '10px 0' }}>
                    <div className="col-12 mb-2">
                        <b>Wifi Info</b>
                    </div>
                    <div className="col-12 col-lg-6">
                        <b>Guest: </b> UCF_Guest
                    </div>
                    <div className="col-12 col-lg-6">
                        <b>Student: </b> UCF_WPA2
                    </div>
                </div> */}
                <div className="row" style={{ margin: '10px 0' }}>
                    <div className="col-12 mb-2">
                        <b>Important Info</b>
                    </div>
                    <div className="col-12 col-lg-6">
                        <b>Email: </b> team@knighthacks.org
                    </div>
                    <div className="col-12 col-lg-6">
                        <b>Code Of Conduct: </b> <a href="https://static.mlh.io/docs/mlh-code-of-conduct.pdf" style={{ color: 'white', textDecoration: 'underline' }}>MLH Code of Conduct</a>
                    </div>
                </div>
                <div className="row" style={{ margin: '25px 0' }}>
                    <div className="col-12 mb-2">
                        <b>Join the conversation!</b>
                    </div>
                    <div className="col-12 col-lg-4">
                        <a href='https://discord.gg/hxk7mZX' rel='noopener noreferrer' target='_blank' className="button-link">
                            <Button
                                className="button green"
                            >
                                <span style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold' }}>
                                    DISCORD
                                </span>
                            </Button>
                        </a>
                    </div>
                    <div className="col-12 col-lg-4">
                        <a href='https://knight-hacks-2020-online.devpost.com/' rel='noopener noreferrer' target='_blank' className="button-link">
                            <Button
                                className="button orange"
                            >
                                <span style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold' }}>
                                    DEVPOST
                                </span>
                            </Button>
                        </a>
                    </div>
                    <div className="col-12 col-lg-4">
                        <a href='https://hopin.to/events/knight-hacks' rel='noopener noreferrer' target='_blank' className="button-link">
                            <Button
                                className="button purple"
                            >
                                <span style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', fontWeight: 'bold' }}>
                                    HOPIN
                                </span>
                            </Button>
                        </a>
                    </div>
                </div>
                {/* <div style={{ margin: '10px 0' }}>
                    <div className="col-12 mb-2">
                        <b>Download our App</b>
                    </div>
                    <img
                        src='./app-store.png'
                        alt='Download on the app store!'
                        className="download-btn"
                        style={{ marginRight: '10px' }} />
                    <img
                        src='./play-store.png'
                        alt='Download on the play store!'
                        className="download-btn"
                        style={{ marginLeft: '10px' }}/>
                </div> */}
            </div>
        </div>
    )
}

export default Countdown;
