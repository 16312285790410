import React from 'react';
import { db } from '../services/firebase.js';
import LiveUpdatesCard from './LiveUpdatesCard.js';

export default class LiveUpdates extends React.Component {
    constructor(props) {
      super(props);

      this.state = {
        componentData: []
      };
    }

    componentDidMount() {
      var dbRef = db.ref('liveUpdates/');

      dbRef.on("value", function(snapshot) {
          const updatesData = [];
          snapshot.forEach(function(doc) {
            var liveItem = doc.val();
            if (liveItem.timeSent != null) {
              liveItem.key = doc.id;
              updatesData.push(liveItem);
            }
          })
          this.setState({componentData: updatesData.sort((a,b) => a.timeSent.seconds - b.timeSent.seconds).reverse()});
        }.bind(this));
    }

    render() {
      const updatesCards = [];

      const { componentData } = this.state;
      var moment = require('moment');

      componentData.forEach((updatesDetail) => {
        var dateObj = moment(new Date(updatesDetail.timeSent * 1000)).format('MM/DD/YY hh:mm a');
        updatesCards.push(
          <LiveUpdatesCard
            key={dateObj}
            message={updatesDetail.message}
            timeSent={dateObj}
          />
        );
      });

      return (
        <div className="updatesTitle">
            <b>LIVE UPDATES</b>
            <div className="updatesScroll">
                {updatesCards}
            </div>
        </div>
      );
    }
}
