import React from 'react';
import './styles/css/style.css';
import 'bootstrap/dist/css/bootstrap.css';
import Header from './components/Header.js';
import Sponsor from './components/Sponsor';
import Countdown from './components/Countdown.js'
import Footer from './components/Footer.js';
import WorkshopsSchedule from './components/WorkshopsSchedule.js';
import MasterSchedule from './components/MasterSchedule.js';
import LiveUpdates from './components/LiveUpdates.js';


function App() {
  return (
    <div className="App">
      <Header />
      <div className="row no-gutters mainHolder">
        <div className="col-12 col-xl-6">
          <div className='live-updates-wrapper'>
            <LiveUpdates />
          </div>
        </div>
        <div className="col-12 col-xl-6">
          <Countdown />
        </div>
        <div className="col-12 col-xl-6 mb-5">
          <div className='master-schedule-wrapper'>
            <MasterSchedule />
          </div>
        </div>
        <div className="col-12 col-xl-6">
          <div className='workshop-schedule-wrapper'>
            <WorkshopsSchedule />
          </div>
        </div>
      </div>
      <div className = "row no-gutters">
        <div className = "col-12 ">
          <Sponsor/>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </div>
  );
}

export default App;
