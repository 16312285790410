import React from 'react';

export default class WorkshopsCard extends React.Component {
  render() {
    return (
      <div className="wkspcard" key={this.props.title}>
          <div className="wkspTitle">{this.props.title}</div>
          {(this.props.description !== 'none' && this.props.description ) && (
            <div className="wkspDetails" style={{ lineHeight: '1.2rem', padding: '0 0.7rem' }}>
              <b className="titleColor">Description: </b>
              {this.props.description}
            </div>
          )}
          <div className="wkspDetails">
            <b className="titleColor">Date: </b>
            {this.props.date}
          </div>
          <div className="wkspDetails">
            <b className="titleColor">Time: </b>
            {this.props.startTime} - {this.props.endTime}
          </div>
          <div className="wkspDetails">
            <b className="titleColor">Location: </b>
            <a href={this.props.location}>Hopin Link</a>
          </div>
      </div>
    )
  }
}
