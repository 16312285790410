import React from 'react'
import { storage } from '../services/firebase';

class SponsorGrid extends React.Component {
    constructor(props) {
        super(props);
  
        this.state = {
          description: this.props.description,
          name : this.props.name,
          offerings : this.props.offerings,
          picture : this.props.picture,
          pictureUrl: null, 
          location: this.props.location,
          flipped: false
        };
        this.flip = this.flip.bind(this)
      }
   componentDidMount(){

        var storageRef = storage.ref()
        var imagesRef = ""
        
        if( typeof this.state.picture === "string")
          imagesRef = storageRef.child(this.state.picture)
        else
          imagesRef = storageRef.child('/sponsor-logos/EA_logo_black.png')

        imagesRef.getDownloadURL().then((snapshot) => { this.setState({ pictureUrl : snapshot }) })
  
   }
   
   render(){
    return (
        <div id = "sponsor-grid">
            <div className = { "sponsor-grid-card" + ((this.state.flipped) ? " flipped" : "") } onClick = {this.flip}>
                <div className = "sponsor-grid-front">
                    <img className="sponsor-grid-image" src={this.state.pictureUrl} alt="sponsor" />
                </div>
                <div className = "sponsor-grid-back">
                    {/* <p>{this.state.offerings}</p> */}
                    <p>{this.state.description}</p>
                </div>
            </div>
        </div>         
    )
   }

   flip = () => {
    this.setState({
      flipped: !this.state.flipped
    })
  }
}

export default SponsorGrid;