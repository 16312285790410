import React from 'react';
// Imports firebase into the component
import { db } from '../services/firebase.js';
import WorkshopsCard from './WorkshopsCard.js';
import moment from 'moment';
import 'moment-timezone';

export default class WorkshopsSchedule extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      componentData: []
    };
  }

  componentDidMount() {
    var dbRef = db.ref('workshops/');
    const wkspData = []

    dbRef.once("value", function(snapshot) {
      snapshot.forEach(function(doc) {
        var wkspItem = doc.val();
        if (wkspItem.startTime != null) {
          wkspItem.key = doc.id;
          wkspData.push(wkspItem);
        }
      })
      const cleanWkspData = wkspData.sort((a,b) => a.startTime.seconds - b.startTime.seconds);
      if (cleanWkspData.length > 4) {
        cleanWkspData.filter((wksp) => wksp.startTime >= + new Date() / 1000);
      }
      this.setState({componentData: cleanWkspData });
    }.bind(this));
  }
  render() {
    const wkspCards = [];
    const { componentData } = this.state;

    componentData.forEach((wkspDetail) => {
      var dateObj = moment(new Date(wkspDetail.startTime.seconds * 1000)).add(4, 'hours').format('MM/DD/YY');
      var startTimeObj = moment(new Date(wkspDetail.startTime.seconds * 1000)).add(4, 'hours').format('hh:mm A');
      var endTimeObj = moment(new Date(wkspDetail.endTime.seconds * 1000)).add(4, 'hours').format('hh:mm A');
      wkspCards.push(
        <WorkshopsCard
          key={wkspDetail.workshopType}
          title={wkspDetail.name}
          description={wkspDetail.description}
          location={wkspDetail.workshopType}
          date={dateObj}
          startTime ={startTimeObj}
          endTime={endTimeObj}
        />
      );
    });

    return (
      <div className="wkspsched">
          <b>WORKSHOPS</b>
          <div className="wkspScroll">
              {wkspCards}
          </div>
      </div>
    );
  }
}
