import React, { Component } from 'react';
import { db } from '../services/firebase.js';
import { Modal, Button, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';

class FeedbackModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            rating: -1,
            feedback: '',
        }
    }

    onRatingChange = (e) => {
        this.setState({ rating: e.target.value })
    }

    onFeedbackChange = (e) => {
        this.setState({ feedback: e.target.value })
    }

    validate = () => {
        const { feedback, rating } = this.state;
        if (rating === -1 || feedback === '') {
            alert('You need to fill out both the rating and feedback fields in order to have your feedback recorded.');
        }
        else {
            this.submit();
        }
    }

    submit = () => {
        const { feedback, rating } = this.state;
        const { handleClose } = this.props;
        const feedRef = db.ref('feedback/');
        const feedObj = {
            feedback: feedback,
            rating: rating,
        }
        const newFeedRef = feedRef.push();
        newFeedRef.set(feedObj);
        this.setState({ rating: -1, feedback: '' });
        handleClose();
    }

    render() {
        const { show, handleClose } = this.props;

        return (
            <div>
                <Modal className="feedbackModal" show={show} onHide={handleClose} size="lg" centered>
                    <Modal.Header closeButton>
                        <Modal.Title>Feedback</Modal.Title>
                    </Modal.Header>
                        <Modal.Body>
                            <p>
                                At Knight Hacks, we find it extremely important to collect as much
                                feedback as we possibly can from those in attendance at our events.
                                For this reason, we look forward to hearing how we're doing from you!
                            </p>
                            <h6>Current Rating</h6>
                            <p style={{ marginBottom: '0px' }}>
                                Please indicate below what your current rating of the event would be?
                            </p>
                            <ToggleButtonGroup type="radio" name="rating" onClick={(e) => this.onRatingChange(e)} style={{ width: '100%', margin: '10px 0' }}>
                                <ToggleButton className="ToggleButton tall" value={1}>1</ToggleButton>
                                <ToggleButton className="ToggleButton tall" value={2}>2</ToggleButton>
                                <ToggleButton className="ToggleButton tall" value={3}>3</ToggleButton>
                                <ToggleButton className="ToggleButton tall" value={4}>4</ToggleButton>
                                <ToggleButton className="ToggleButton tall" value={5}>5</ToggleButton>
                                <ToggleButton className="ToggleButton tall" value={6}>6</ToggleButton>
                                <ToggleButton className="ToggleButton tall" value={7}>7</ToggleButton>
                                <ToggleButton className="ToggleButton tall" value={8}>8</ToggleButton>
                                <ToggleButton className="ToggleButton tall" value={9}>9</ToggleButton>
                                <ToggleButton className="ToggleButton tall" value={10}>10</ToggleButton>
                            </ToggleButtonGroup>
                            <h6>Comments / Concerns</h6>
                            <p>
                            Please enter any comments or concerns you have abut the event below. Feel 
                            free to indicate everything you think we're doing well, not well, and your 
                            overall experience at the event.
                            </p>
                            <textarea
                                className="form-control"
                                rows="4"
                                cols="50"
                                placeholder="Enter your feedback here..."
                                onChange={(e) => this.onFeedbackChange(e)}>
                            </textarea>
                        </Modal.Body>
                    <Modal.Footer>
                        <Button className="CloseButton" onClick={handleClose}>
                            Close
                        </Button>
                        <Button className="ToggleButton" onClick={this.validate}>
                            Submit
                        </Button>
                    </Modal.Footer>
                </Modal>
            </div>
        );
    };
}

export default FeedbackModal