import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import FeedbackModal from './FeedbackModal';

class Footer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        }
    }

    handleShow = () => {
        this.setState({ show: true });
    }

    handleClose = () => {
        this.setState({ show: false });
    }

    render() {
        const {show} = this.state;

        return (
            <div className="footer">
                <div className="content-holder">
                    <div className="row content-row align-items-center">
                        <div className="col-12 col-lg order-2 order-lg-1 text-center">
                            <div className="download-text">Join the Conversation</div>
                            {/* <img 
                                src='./app-store.png'
                                alt='Download on the app store!'
                                className="download-btn"
                                style={{ marginRight: '10px' }} />
                            <img
                                src='./play-store.png'
                                alt='Download on the play store!'
                                className="download-btn"
                                style={{ marginLeft: '10px' }}/> */}
                            <a href='https://discord.gg/hxk7mZX' rel='noopener noreferrer' target='_blank'>
                                <Button
                                    className="download-btn green mr-2"
                                    onClick={this.handleShow}
                                >
                                    <b>DISCORD</b>
                                </Button>
                            </a>
                            <a href='https://knight-hacks-2020-online.devpost.com/' rel='noopener noreferrer' target='_blank'>
                            <Button
                                className="download-btn orange ml-2"
                                onClick={this.handleShow}
                            >
                                <b>DEVPOST</b>
                            </Button>
                            </a>
                        </div>
                        <div className="col-12 col-lg order-1 order-lg-2">
                            <Button
                                className="feedback"
                                onClick={this.handleShow}
                            >
                                <span style={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    GIVE US FEEDBACK!
                                </span>
                            </Button>
                        </div>
                        <div className="col-12 order-3">
                            <div style={{ textAlign: 'center' }}>Knight Hacks 2020</div>
                        </div>
                    </div>
                </div>
                <FeedbackModal show={show} handleClose={this.handleClose} />
            </div>
        );
    }
}

export default Footer;