import React from 'react'
import { db } from '../services/firebase'
import SponsorGrid from './SponsorsGrid'

class Sponsor extends React.Component {
   
    constructor(props) {
      super(props);

      this.state = {
        componentData: []
      };
    }

  componentDidMount() {
    const sponsorRef = db.ref('sponsors/')
    var sponsorsObj = []

    sponsorRef.once('value', function(snapshot) {
      snapshot.forEach(function(item) {
        var itemVal = item.val();
        itemVal.key = item.key
        sponsorsObj.push(itemVal)
      })
      this.setState({componentData : sponsorsObj})
    }.bind(this))
  }
  render() {
    const sponsorsInfo = [];
    this.state.componentData.forEach((sponsor) => {
        sponsorsInfo.push(
          <SponsorGrid
            key={sponsor.name}
            description={sponsor.description}
            location={sponsor.location}
            name = {sponsor.name}
            offerings = {sponsor.offerings}
            picture={sponsor.picture}
          />
        );
    });
  
    return (
      <div id = "sponsor">
        <h2 className = "sponsor-header">Sponsors</h2>
        <div className = "sponsor-container">
          {sponsorsInfo}
        </div>
      </div>
    );
  }

}


export default Sponsor;