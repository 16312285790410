import firebase from 'firebase/app';
import 'firebase/app';
import 'firebase/database'; 
import 'firebase/storage';

const firebaseConfig = {
  apiKey: 'AIzaSyCfMDsLsbEvp5ekyDUqHSx_brk3nFAoYv8',
  authDomain: 'knighthacks-backend.firebaseapp.com',
  databaseURL: 'https://knighthacks-backend.firebaseio.com',
  projectId: 'knighthacks-backend',
  storageBucket: 'knighthacks-backend.appspot.com',
  messagingSenderId: '1095359822390',
  appId: '1:1095359822390:web:d9edc4a40cf7dbcda97697',
  measurementId: 'G-2EFFEEGW09'
};

firebase.initializeApp(firebaseConfig);
export const db = firebase.database();
export const storage = firebase.storage();